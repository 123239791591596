
        @import "@/styles/griddle-overrides.scss";
        @import "@braid/griddle/scss/griddle.scss";
        














































































































@import '@/styles/_colors.scss';

.users,
.selected-user,
.add-users {
  display: flex;
  flex-direction: column;
  height: 32rem;
  background: white;
  border-radius: 3px;
  box-shadow: 0 2px 4px rgba($color-grey-400, 0.3);
}

.users__list {
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.users__list-item {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  color: var(--color-text);
  font-weight: 500;
  background: none;
  border: 0;
  border-bottom: 1px solid $color-grey-100;
  cursor: pointer;

  &:hover {
    background: $color-grey-50;

    & > .users__list-item-chevron {
      opacity: 1;
    }
  }
}

.users__list-item-chevron {
  margin-left: auto;
  padding-left: 0.25rem;
  opacity: 0.25;
}

.users__list-item-icon {
  flex: 0 0 1.75rem;
  text-align: left;
}

.users__list-item-name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.users__footer {
  margin-top: auto;
  padding: 1rem;
  font-size: 0.79rem;
}
